import React from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

const HowUseContent = ({ pageContent, data }: { pageContent?: any; data?: any }) => {
  const { t } = useTranslation();
  const newMap = new Map<string, any>([
    [
      'virtual-hair-color-try-on',
      {
        h2: 'How to Use Hairpaca?',
        h3List: [
          'Choose a hair color from a wide range of shades',
          'Click "Try-On" to see how different colors look on you instantly',
          'Find your perfect hair color match',
        ],
      },
    ],
    [
      'hair-types',
      {
        h2: 'How to Use Hairpaca?',
        h3List: [
          'Find Styles for Your Hair Type',
          'Click "Try-On" to Test Different Looks',
          'Find Your Perfect Hairstyle Match',
        ],
      },
    ],
    [
      'best-hairstyles-for-face-shape',
      {
        h2: 'How to Use Hairpaca?',
        h3List: [
          'Explore a variety of styles designed for different face shapes',
          'Click "Try-On" to see how the hairstyle looks on you instantly',
          'Choose the Best Hairstyle for Your Face Shape',
        ],
      },
    ],
    [
      'trending-hairstyles',
      {
        h2: 'How to Use Hairpaca?',
        h3List: [
          'Discover the latest trending hairstyles for men and women',
          'Click "Try-On" to see how different haircuts look on you instantly',
          'Choose the perfect trendy hairstyle for 2025',
        ],
      },
    ],
  ]);

  return (
    <div className={styles.content}>
      <h2 style={{ display: 'none' }}>{newMap.get(data?.pathId)?.h2}</h2>
      <div className={styles.h2}>
        <span>{pageContent?.contentInfo?.['section-how']?.title}</span>
      </div>

      <div className={styles.container}>
        <Image
          className={styles.bigImgItem}
          src={'/images/hairstyle/how_to_use_bg.webp'}
          alt="icon"
          loading="lazy"
          width={1028}
          height={291}
        />

        <div className={styles.descList}>
          <div>
            <span>{pageContent?.contentInfo?.['section-how']?.desc[0]}</span>
            <h3 style={{ display: 'none' }}>{newMap.get(data?.pathId)?.h3List[0]}</h3>
          </div>
          <div>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: pageContent?.contentInfo?.['section-how']?.desc[1].replace(
                    'Try-On',
                    '<span style="color: #68F3F6">Try-On</span>',
                  ),
                }}
              ></span>
            </span>
            <h3 style={{ display: 'none' }}>{newMap.get(data?.pathId)?.h3List[1]}</h3>
          </div>

          <div>
            <span>{pageContent?.contentInfo?.['section-how']?.desc[2]}</span>
            <h3 style={{ display: 'none' }}>{newMap.get(data?.pathId)?.h3List[2]}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowUseContent;
